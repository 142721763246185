export enum RolesEnum {
    ROLE_SUPERADMIN = 'ROLE_SUPERADMIN',
    ROLE_ADMIN_PLAQUE = 'ROLE_ADMIN_PLAQUE',
    ROLE_ADMIN = 'ROLE_ADMIN',
    ROLE_CLIENT = 'ROLE_CLIENT',
    ROLE_FACTURATION = 'ROLE_FACTURATION',
    ROLE_CALL = 'ROLE_CALL',
    ROLE_EXPLOITATION = 'ROLE_EXPLOITATION',
    ROLE_ADMIN_EXPLOITATION = 'ROLE_ADMIN_EXPLOITATION',
    ROLE_COMMERCIAL = 'ROLE_COMMERCIAL',
    ROLE_ADMIN_COMMERCIAL = 'ROLE_ADMIN_COMMERCIAL',
    ROLE_USER = 'ROLE_USER',
}

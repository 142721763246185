import { RouteRecordRaw } from 'vue-router';
import { TrigrammeRetourEnum } from '@/enum/trigrammeRetour.enum';
import permissionsMapping from "@/config/permissionsMapping";

const section = 'retours';
const retoursAdminRoutes: RouteRecordRaw[] = [
    {
        path: '/admin/' + section,
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: section + '-admin-toutes-les-demandes',
                redirect: {
                    name: section + '-toutes-les-demandes',
                    params: { trigramme: TrigrammeRetourEnum.TOUTES_LES_DEMANDES },
                },
                children: [
                    {
                        path: ':trigramme?',
                        name: section + '-toutes-les-demandes',
                        component: () => import('@/domaine/retours/admin/demandes-retour/composants/liste/liste-demandes.vue'),
                    },
                    {
                        path: 'garanties-psa',
                        children: [
                            {
                                path: '',
                                name: section + '-garanties-psa',
                                component: () => import('@/domaine/retours/admin/garanties-psa/liste-garanties.vue'),
                            },
                        ],
                    },
                ],
            },
            {
                path: 'nouvelle-demande',
                name: section + '-nouvelle-demande',
                component: () => import('@/domaine/retours/admin/demandes-retour/nouvelle-demande.vue'),
            },
            // {
            //     path: 'edition-demande/:id',
            //     name: section + '-edition-demande',
            //     component: () => import('@/domaine/retours/admin/demandes-retour/composants/edition/edition-demande.vue'),
            // },
            {
                path: 'motifs',
                children: [
                    {
                        path: '',
                        name: section + '-motifs',
                        component: () => import('@/domaine/retours/admin/motifs-retour/liste-motifs.vue'),
                    },
                    {
                        path: ':id?/edition',
                        name: section + '-motifs-edition',
                        component: () => import('@/domaine/retours/admin/motifs-retour/edition-motif.vue'),
                    },
                ],
            },
        ],
    },
];

export default retoursAdminRoutes;

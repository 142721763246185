import { RouteRecordRaw } from 'vue-router';
import { TrigrammeRetourEnum } from '@/enum/trigrammeRetour.enum';
import permissionsMapping from "@/config/permissionsMapping";
import {RolesEnum} from "@/enum/roles";

const section = 'administration';
const parametreAdminRoutes: RouteRecordRaw[] = [
    {
        path: '/admin/' + section,
        component: () => import('@/composants/structures/default/template-page-admin.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: section,
                component: () => '',
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'plaques',
                name: section + '-plaques',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'utilisateurs',
                name: section + '-utilisateurs',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
                children: [
                    {
                        path: 'employes',
                        name: section + '-utilisateurs-employes',
                        component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                        meta: {
                            permission: RolesEnum.ROLE_SUPERADMIN,
                        },
                    },
                    {
                        path: 'roles',
                        name: section + '-utilisateurs-roles',
                        component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                        meta: {
                            permission: RolesEnum.ROLE_SUPERADMIN,
                        },
                    },
                ],
            },
            {
                path: 'clients',
                name: section + '-clients',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'fournisseurs',
                name: section + '-fournisseurs',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'retours',
                name: section + '-retours',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'parametres',
                name: section + '-parametres',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
            {
                path: 'indicateurs',
                name: section + '-indicateurs',
                component: () => import('@/domaine/parametres/parametre-admin-template.vue'),
                meta: {
                    permission: RolesEnum.ROLE_SUPERADMIN,
                },
            },
        ],
    },
];

export default parametreAdminRoutes;

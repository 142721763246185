import permissionsMapping from "@/config/permissionsMapping";

export default [
    {
        path: '/clients',
        component: () => import('@/composants/structures/default/template-page-defaut.vue'),
        meta: {},
        children: [
            {
                path: '',
                name: 'liste-fiches-client',
                component: () => import('@/domaine/clients/liste-fiches-client.vue'),
                meta: {
                    layout: 'template-page-defaut',
                },
            },
            {
                path: 'fiche',
                name: 'fiche-client',
                component: () => import('@/domaine/clients/fiche-client.vue'),
                meta: {
                    layout: 'template-page-defaut',
                },
            },
        ],
    },
];
